.forgotpassword {
  display: block;
  text-align: right;
}

.loginbtn {
  button {
    width: 100%;
    height: 40px;
  }
}

.searchresetbtn {
  .ant-form-item-control-input-content {
    display: flex;
    gap: 10px;
  }
}

.add-cms-key {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.centerBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_innermodal {
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin-top: 20px;

  .coinlist_modal {
    @media (max-width:575px) {
      flex-direction: column;
    }

    >input,
    >div {
      width: 48%;

      @media (max-width:575px) {
        width: 100%;
      }
    }
  }

  .buttons_group {
    display: flex;
    gap: 10px;

    button {
      width: 50%;
    }
  }

}

.displayFlex {
  display: flex;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.justifyContentCenter {
  justify-content: center;
}

.PaginationRow {
  padding: 20px;
}

// .ant-table-wrapper {
//   height: 500px;
//   // overflow-y: scroll;
// }

.ant-layout-content {
  // padding: 30px !important;

  // @media (max-width:575) {
  //   padding: 15px !important;
  // }

  .site-layout-content {
    .ant-card {
      height: 100%;
    }
  }
}

.alert_page {
  .ant-table-content {
    overflow-x: auto;

    td,
    th {
      white-space: nowrap;
    }
  }
}

.ant-layout-content.main.content-section {
  min-height: 100vh;
}



.recentVender_Page {
  // @media (max-width:757px) {
  //   // .ant-card-head {
  //   //   // flex-direction: column;

  //   //   // .ant-card-head-wrapper {
  //   //   //   // flex-direction: column;
  //   //   //   // padding: 5px;

  //   //   //   // .ant-card-head-title {
  //   //   //   //   white-space: wrap;
  //   //   //   // }
  //   //   // }
  //   // }
  // }



  ul {
    li {
      @media (max-width:767px) {
        // flex-direction: column !important;
        // gap: 20px !important;

        .ant-list-item-meta {
          width: 100%;

          .ant-list-item-meta-description {

            word-break: break-all;
          }
        }
      }
    }

  }
}

.DefaultPage {
  .ant-card-head-wrapper {
    @media (max-width:575px) {
      // padding: 10px;
      // flex-direction: column;

      .ant-card-extra {
        margin: auto;
      }
    }
  }
}

.cmcKeyInfo {
  .ant-card-body {
    padding: 0px 12px;
  }
}

.dashCard {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  flex-flow: wrap;

  >div,
  .ant-statistic {
    max-width: 100%;
    width: 100%;
    flex: 1;

    @media screen and (max-width:1320px) {
      min-width: 290px;
    }
  }

  .ant-statistic-title {
    white-space: nowrap;
  }
}



.width100 {
  width: 100%;
}

.textAlignRight {
  text-align: right;
}

.displayInlineGrid {
  display: inline-grid !important;
}

.displayBlock {
  display: block !important;
}