#snackbarSuccess {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: #00AF84;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 10px;
    position: fixed;
    z-index: 1;
    top: 30px;
    right: 30px;
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 5px;
  }
  p {
    margin-bottom: 0px !important;
  }
  .main-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
  }
  #snackbarError {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: #E45A5A;
    color: #ffffff;
    text-align: center;
    border-radius: 2px;
    padding: 10px;
    position: fixed;
    z-index: 1;
    top: 30px;
    right: 30px;
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    border-radius: 5px;
  }
  #snackbarSuccess.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
  }
  
  #snackbarError.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
  }
  
  @-webkit-keyframes fadein {
    from {
      right: 0;
      opacity: 0;
    }
    to {
      right: 30px;
      opacity: 1;
    }
  }
  
  @keyframes fadein {
    from {
      right: 0;
      opacity: 0;
    }
    to {
      right: 30px;
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadeout {
    from {
      right: 30px;
      opacity: 1;
    }
    to {
      right: 0;
      opacity: 0;
    }
  }
  
  @keyframes fadeout {
    from {
      right: 30px;
      opacity: 1;
    }
    to {
      right: 0;
      opacity: 0;
    }
  }
  
  .close-btn {
    font-size: 12px;
    cursor: pointer;
    font-weight: 500;
    color: white;
    margin-left: 5px;
  }
  
  .snak-icon {
    margin-right: 5px;
    margin-left: 5px;
  }
  
  .snak-message {
    display: flex;
    flex-wrap: wrap;
    flex-flow: row;
    text-align: start;
    justify-content: flex-start;
    align-items: flex-start;
    height: fit-content;
    width: 200px;
    min-width: 180px;
    margin-right: 15px;
    margin-left: 15px;
  }
  
  .snak-message-error{
    text-align: start;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: fit-content;
    width: 200px;
    min-width: 180px;
    margin-right: 15px;
    margin-left: 15px;
  }
  
  .message-text{
    line-height: 20px;
  }
  

:where(.css-dev-only-do-not-override-17a39f8).ant-layout .ant-layout-sider-children {
    height: 100%;
    margin-top: -0.1px;
    background: white;
    padding-top: 0.1px;
}
:where(.css-dev-only-do-not-override-17a39f8).ant-layout .ant-layout-sider-trigger {
  background-color:#1677ff;
}
:where(.css-dev-only-do-not-override-17a39f8).ant-layout .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: auto;
}
