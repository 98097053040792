body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

.vendortable {
   table {
      tbody {
         td {
            white-space: nowrap !important;
         }
      }
   }
}

.coinlist_tocken {
   .ant-card-head {
      @media (max-width:767px) {
         padding: 10px;
      }
   }

   button {
      box-sizing: border-box;
      padding: 6px 10px;
   }

   .ant-card-body {
      @media (max-width:767px) {
         padding: 20px 0;
      }
   }

   .ant-card-head-wrapper {
      @media (max-width:767px) {
         display: block !important;
      }

      .ant-card-head-title {
         display: block;
         width: 100%;
      }
   }

   button {
      width: 100% !important;
   }
}

.coin_list_table {
   td {
      white-space: nowrap;
   }


}


.PlanList {
   .ant-card-head-wrapper {
      @media (max-width:575px) {
         display: block !important;
      }
   }

   .ant-card-head {
      padding: 20px 10px;

      .ant-card-head-title {
         margin-bottom: 10px;
      }
   }


   .ant-card-head-title {


      @media (max-width:767px) {
         display: block;
         width: 100%;
      }
   }

   td,
   th {
      white-space: nowrap !important;
   }

   .ant-table-content {
      overflow-x: auto;
   }
}

.coinListPage {
   padding: 10px;
}

.cmc_key {
   .ant-table-content {
      overflow-x: auto;
   }

   form {
      @media (max-width:767px) {
         margin-bottom: 20px !important;


      }
   }

   th,
   td {
      white-space: nowrap;
   }
}

.coinlist_modal {
   display: flex;
   gap: 20px;

   .ant-form-item {
      margin-bottom: 0px;

   }
}

.ant-collapse-content-box {
   overflow-y: auto;
}

.listStyle {
   .ant-list-item {
      margin-bottom: 2px;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      padding: 16px;
      // transition: background-color 0.3s;
      transition: background-color 0.3s, transform 0.2s;


      // .ant-list-item-meta {
      //   width: 40%;
      // }

      // .ant-row {
      //   width: 60%;
      // }
   }

   .vender-list-item:hover {
      background-color: rgba(0, 0, 0, 0.1);
      /* Light gray background on hover */
      transform: scale(1.02);
      /* Slightly enlarge the item */
      cursor: pointer;
      /* Change cursor to pointer */
   }
}


.ant-segmented-group {
   flex-wrap: wrap;
}


.headerNameRgtCol {
   display: flex;
   justify-content: end;
   width: 100%;
   padding-top: 10px;
   align-items: center;
}

.dashboard-header {
   display: flex;
   height: 80px;
   background: rgb(255, 255, 255);
   border-bottom: 1px solid;
   box-shadow: rgba(153, 153, 153, 0.5) 0px 0px 40px 10px;

   .logo {
      a {
         display: flex;
         align-items: center;

         img {
            height: 60px;
            width: 170px;
            margin-top: 10px;

            @media (max-width:767px) {
               height: 40px;
               width: 140px;
            }
         }
      }

   }
}

.recentVenderRequestList {
   ul {
      li {
         @media (max-width:767px) {
            flex-direction: column !important;
            gap: 20px !important;
         }
      }
   }
}


.coinListTable {
   .coinListExtraInfo {
      .ant-collapse-content-box {
         word-wrap: break-word;
         /* IE 5.5-7 */
         white-space: -moz-pre-wrap;
         /* Firefox 1.0-2.0 */
         white-space: pre-wrap;
         /* current browsers */
      }
   }
}


.demo-logo {
   width: 20%;
   justify-content: center;

   .logoSpanBx {
      font-size: 20px;

      img.logoImg {
         min-width: 140px;
         max-width: 170px;
         margin-top: 10px;
      }
   }
}

//////////////////////////// media query max-width 452px
@media (max-width: 425px) {
   .dashboard-header {
      padding: 0 20px;
   }

   .dashboardPage {
      .ant-card-body {
         padding: 10px !important;
      }
   }

   // main.ant-layout-content {
   //    padding: 0px !important;
   // }

   .ant-card .ant-card-head-wrapper {
      flex-wrap: wrap;
   }

   .ant-card-head-title {
      padding: 5px 0px;
   }

   .headerNameSec {
      margin-right: 0 !important;
      max-width: 110px;
      overflow: hidden;
      line-height: 1.3;
      margin-left: 10 !important;
   }

   .expiringSubscriptionCard {
      .expiringSubscriptionList {
         ul {
            li.vender-list-item {
               span {
                  text-align: left;
                  font-size: 12px;
               }
            }
         }
      }
   }

   .ant-layout-sider-collapsed.dashboard-sidebar {
      flex: 0 0 50px !important;
      max-width: 50px !important;
      min-width: 50px !important;
      width: 50px !important;
   }

   .ant-layout-sider-trigger {
      max-width: 50px;
      width: 50px;
   }
}



.loginInnerSec.loginpage {
   padding-top: 20px;
   box-shadow: rgb(153, 153, 153, 0.5) 0px 0px 40px 10px;
   border-radius: 22px;

   .logoCol{
      border-right: 1px solid rgb(153, 153, 153);
   }
 
   @media (min-width:991px) {
     min-height: 100%;
   }

   @media (max-width: 425px) {
      border-radius: 0;
      box-shadow: rgb(153, 153, 153, 0.5) 0px 0px 25px 4px;

      .logoCol{
         border-right: none;
      }  
   }
 }